import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/about/plant.js"
import BgImage from "../../assets/images/2017/08/oil-rig-australia-slide.jpg"

const PlantPage = () => {
  const title = "Plant"
  const content = "World class and custom in-house engineered machinery"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default PlantPage
