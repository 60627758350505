import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          Fixed & semi-portable CNC induction bending machines to 900NB pipe,
          induction heaters solid state and MG sets (50 to 550KW & 500 to
          10,000Hz) for onshore and offshore applications.
        </p>
      </div>
    </div>
  )
}

export default PageContent
